/* .btnAddFolder {
  margin-top: 7.75rem;
} */
.modalDeleteButtons {
  display: flex;
}

.modalDeleteButtons > *:first-child {
  margin-right: 0.625rem;
}

.modalDeleteButtons > *:last-child {
  margin-left: 0.625rem;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
}

.empty {
  background-color: var(--background);
  text-align: center;
  padding: 6.6875rem 0;
  color: var(--secondary-text);
}

.separator {
  margin-top: 0.625rem;
  margin-bottom: 0.3125rem;
}

.search {
  display: flex;
  align-items: stretch;
  margin: 1.25rem;
  position: relative;
}

.searchSelect {
  min-width: 8rem;
}

.searchInput {
  width: 40%;
  /* margin-left: 1.25rem; */
  margin-bottom: 0 !important;
}

.isLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.1429rem;
  width: 7.1429rem;
  position: fixed;
  bottom: 0.1rem;
  right: 0.1rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 25px;
  z-index: 10;
}

.filterIcon {
  width: 1.5rem;
  color: var(--black);
  background-color: #fff;
  padding-left: 0.625rem;
  margin-left: 1rem;
  box-sizing: content-box;
}

.filterValue {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-text);
}

.filterValue span {
  padding-right: 1rem;
}

.slidersIcon {
  width: 1rem;
  color: var(--unselected-icon);
  background-color: #fff;
  padding-right: 0.625rem;
  /* margin-left: 1rem; */
  box-sizing: content-box;
}

.slidersPopOver {
  width: 75%;
  height: 100%;

  font-weight: 500;
  background-color: white;
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translateY(0.5rem) translateX(5rem);
  color: var(--black);

  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;

  filter: var(--medium-drop-shadow);
}

.slidersOption {
  margin-bottom: 1rem;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.folderIcon {
  color: var(--secondary-color);
}

.userIcon {
  color: var(--black);
}

.allFolder {
  color: var(--blue);
  font-weight: 600;
  cursor: pointer;
}

.allFolder:hover {
  text-decoration: underline;
}

.multiValueContainer {
  display: flex;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.formatOptionFolder {
  margin-left: 0.5rem;
  white-space: break-spaces;
  word-break: break-all;
}

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.disabled {
  filter: grayscale(1);
  cursor: not-allowed !important;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blue-medium);
  opacity: 0.24;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: -2px;
  bottom: -2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  filter: drop-shadow(0px 1px 2px rgba(48, 79, 254, 1));
}

input:checked + .slider {
  opacity: 0.9;
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

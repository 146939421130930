.fakeTopbar {
  height: 4.375rem;
}

.topbar {
  position: fixed;
  height: 4.375rem;
  padding: 0.625rem;
  background-color: var(--white);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
}

.rightItems {
  display: flex;
  align-items: center;
  position: relative;
}

.userCircle {
  width: 2.2rem;
  height: 2.2rem;
}

.caretDown {
  width: 1rem;
  height: 1rem;
  transition-duration: 250ms;
}

.reverseCaretDown {
  transform: rotateX(180deg);
}

.userName {
  font-weight: 500;
}

.userRole {
  color: var(--secondary-text);
  font-weight: 400;
  font-style: italic;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brandLogo {
  height: 100%;
  cursor: pointer;
  display: flex;
}

.brandLogoIcon {
  height: 100%;
}

.brandLogoText {
  font-weight: 700;
  color: var(--blue);
  font-size: 2rem;
  line-height: 3.125rem;
}

.collapseButton {
  display: flex;
  align-items: center;
  margin-left: 0.75rem;
}

.collapseButton:hover {
  cursor: pointer;
  color: var(--blue-darker);
}

.menu {
  position: absolute;
  top: 2.75rem;
  /* left: 0; */
  /* width: 100%; */
  background-color: var(--white);

  border: 1px solid var(--border-color);
  box-sizing: border-box;

  box-shadow: var(--drop-shadow);
  border-radius: 0.3125rem;

  right: 0;
  white-space: nowrap;
}

.menu p:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.menu p:last-child {
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.menu p {
  margin: 0;
  padding: 7px 15px;
  transition: background-color 150ms;
  cursor: pointer;
}

.menu p:not(:last-child):hover {
  color: var(--white);
  background-color: var(--blue);
}

.onboardingMenu {
  position: absolute;
  top: 2.75rem;
  border: 3px solid var(--border-color);
  filter: var(--medium-drop-shadow);
  left: -15rem;
  white-space: nowrap;
  /* width: 20rem; */
  border-radius: 1rem;
  /* border-top-left-radius: 1rem;
  border-top-right-radius: 1rem; */
  background-color: white !important;
  display: flex;
  flex-direction: column;
}

.disconnect {
  background-color: var(--white);
  border-top: 1px solid var(--border-color);
  color: var(--red);
  display: flex;
  align-items: center;
}

.disconnect svg {
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
}

.disconnect:hover {
  background-color: var(--red);
  color: var(--white);
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--border-color);
}

.itemList {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.listItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1.0714rem;
  padding-right: 1.0714rem;
  padding-top: 0.7143rem;
  padding-bottom: 0.7143rem;
}

.listItem:not(:last-child) {
  border-bottom: 3px solid var(--border-color);
}

.listItem:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.listItem:hover {
  background-color: white;
  filter: var(--medium-drop-shadow);
}

.onboardingMenu span:first-child {
  background-color: var(--primary-color);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.textContent {
  color: var(--background);
  font-weight: 600;
  padding: 1rem;
}

.onboarding {
  margin-right: 2rem;
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;
}

.faIcon {
  margin-right: 1rem;
}

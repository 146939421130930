.container {
  width: 100%;

  font-weight: 500;
  background-color: white;
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translateY(0.5rem);
  color: var(--black);
}

.container div {
  width: 100%;
  padding: 0.5rem 0.625rem 0.4375rem 0.625rem;
  border: 0.0625rem solid #f2f4f9;
}

.container div:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.container div:last-child {
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.container div:hover {
  color: var(--blue);
  cursor: pointer;
}

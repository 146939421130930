.categoryContainer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.categoryContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--border-color);
}

.category {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category .label:hover,
.category .folderIcon:hover,
.subCategory > *:hover {
  cursor: pointer;
  color: var(--blue);
}

.folderIcon {
  color: var(--folder);
}

.category > div {
  display: flex;
  align-items: center;
}

.label {
  font-weight: 500;
  margin-left: 0.75rem;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.icon:hover {
  color: var(--blue);
  cursor: pointer;
}

.subCategories {
  margin-left: 2rem;
}

.subCategory {
  display: flex;
  align-items: center;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  position: relative;
}

.subCategory:last-of-type {
  padding-bottom: 0;
}

.subCategory .label {
  font-weight: 400;
}

.folderOptions {
  display: flex;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  margin-left: -0.375rem;
}

.folderOptions:hover {
  cursor: pointer;
  color: var(--blue);
}

.checkbox {
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 0.125rem;
}

.checkbox:checked {
  border: 1px solid var(--unselected-icon) !important;
}

.content {
  overflow-y: auto;
  padding-right: 1.4375rem;
  margin-right: -1.4375rem;
  max-height: 50vh;
}

.clone {
  width: 4rem;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}

.footer > *:first-child {
  margin-right: 0.625rem;
}

.footer > *:last-child {
  margin-left: 0.625rem;
}

.subCategoryCheckbox {
  margin-left: 0.375rem;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.subCategoryIcon {
  color: var(--folder);
}

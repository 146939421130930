.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.mainContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.homeContainer {
  height: 21.4286rem;
  position: relative;
  max-width: 32%;
  flex-basis: 32%;
  border: 1px solid var(--background);
  padding: 0.7143rem 1.0714rem 1.0714rem 1.0714rem;
  /* margin-right: 12px; */
  margin-bottom: 1.1429rem;
  border-radius: 5px;
  transition-duration: 100ms;
  background-color: white !important;
  display: flex;
  flex-direction: column;
}

.homeContainerEmpty {
  flex-basis: 32%;
}

.homeContainer:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.clickable:hover {
  cursor: pointer;
}

.homeContainerTitle {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1429rem;
  line-height: 1.4286rem;
  margin-bottom: 1rem;
}

.homeContainerChildren {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* background-color: pink; */
}

.video {
  width: 100%;
  height: 100%;
}

.itemImage {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.itemList {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.clickableItem {
  color: var(--primary-color);
  text-decoration-line: underline;
}

.listItem {
  margin-top: 1.625rem;
}

.mobileImage {
  margin: 2rem;
}

.itemContainer {
  flex: 1;
  position: relative;
  margin-top: 1rem;
}

.faIcon {
  margin-right: 1rem;
  color: var(--primary-color);
}

.updateModal {
  width: 50rem !important;
  max-width: 50%;
}

.tutoContent .listItem:hover {
  background-color: #fff;
  cursor: pointer;
  -webkit-filter: var(--medium-drop-shadow);
  filter: var(--medium-drop-shadow);
}

.linkableContainer {
  width: 100%;
  height: 100%;
  background-color: var(--background);

  position: relative;
}

.linkableIcon {
  width: 20%;
  height: 20%;

  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.linkableLogo {
  width: 30%;

  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

.mediaLogo {
  left: 10%;
}

.kaizenLogo {
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 6s;

  right: 10%;
}

.catalogueLogo {
  animation-delay: -3s;

  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 6s;

  right: 10%;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.widthContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.left {
  display: flex;
  flex-direction: column;
  padding: 45px 20px 15px 15px;
  height: calc(100vh - 60px);
  width: 18.125rem;
  position: fixed;
  background-color: var(--primary-color);
  transition: width 0.5s ease;
  white-space: nowrap;

  justify-content: space-between;
}

.left:not(.opened) {
  width: 45px;
  transition: width 0.5s ease;
}

.left a.selectedRow {
  color: white;
  font-weight: 600;
}

.left a:not(.selectedRow) {
  color: rgba(255, 255, 255, 0.4);
}

.left a {
  color: #f7f9f9;
  margin-top: 2px;
  margin-bottom: 2px;
  text-decoration: none;
}

.left a:hover {
  text-decoration: none;
  font-weight: 600;
}

.left p {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
  transition: opacity 0.6s ease;
}

.left p.firstTitle {
  margin-top: 0;
}

.left:not(.opened) .selectedRow {
  border-right: solid 5px var(--background);
  margin-right: -20px;
}

.left:not(.opened) .menuRowText {
  pointer-events: none;
}

.opened .selectedRow::after {
  display: inline-block;
  height: 0;
  width: 0;
  content: "";
  border-top: 9px solid transparent;
  border-right: 15px solid var(--background);
  border-bottom: 9px solid transparent;
  font-weight: 600;
  position: absolute;
  right: -1px;
  margin-top: 2px;
}

.right {
  margin-left: 18.125rem;
  padding: 10px;
  flex: 1;
  max-width: calc(100vw - 18.125rem);
  transition: all 0.5s;
}

.right:not(.opened) {
  margin-left: 35px;
  max-width: calc(100vw - 35px);
}

.openCloseIcon {
  position: absolute;
  top: 0px;
  right: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 32px;
}

.openCloseIcon:hover {
  cursor: pointer;
}

.left:not(.opened) .menuRow {
  white-space: nowrap;
}

.left:not(.opened) > p {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.menuRowText {
  opacity: 1;
  transition: opacity 0.6s ease;
}

.left:not(.opened) .menuRowText {
  opacity: 0;
  transition: opacity 0.6s ease;
}

.iconContainer {
  width: 32px;
  text-align: center;
  display: inline-block;
  margin-left: -10px;
}

.categoryContainer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.categoryContainer:not(:last-of-type) {
  border-bottom: 1px solid var(--border-color);
}

.category {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category .label:not(.selected) {
  font-weight: 500;
}

.category .label:hover,
.category .folderIcon:hover {
  cursor: pointer;
  color: var(--blue);
}

.folderIcon {
  min-width: 18px;
  min-height: 14.87px;
  align-self: center;
}

.folderPrivate {
  color: var(--folderPrivate) !important;
}

.folderPublic {
  color: var(--folder) !important;
}

.category > div {
  display: flex;
  align-items: center;
}

.label {
  margin-left: 0.75rem;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon {
  width: 1rem;
  height: 1rem;
}

.icon:hover {
  color: var(--blue);
  cursor: pointer;
}

.subCategories {
  margin-left: 2rem;
}

.subCategory {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}

.subCategory:last-of-type {
  padding-bottom: 0;
}

.subCategory .label:not(.selected) {
  font-weight: 400;
}

.selected {
  color: var(--blue);
  font-weight: 500;
}

.threeDotsIcon {
  height: 100%;
  padding: 0.5rem;
}

.threeDotsIcon:hover {
  /* box-shadow: 0 0 0 0.1px var(--button-hover); */
  border-radius: 25%;
}

.folderOptions {
  display: flex;
  padding-right: 0.375rem;
  padding-left: 0.375rem;
  margin-left: -0.375rem;
}

.folderOptions:hover {
  cursor: pointer;
  color: var(--blue);
}

.popOver {
  filter: var(--drop-shadow-darker);
}

.empty {
  background-color: var(--background);
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.noAccess {
  color: var(--input-text-disabled);
}

.label {
  font-weight: 600;
  font-size: 0.875rem;
}

.range {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.filePreview {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  position: relative;
}

.filePreview.empty {
  background-color: var(--background);
  color: var(--secondary-text);
}

.input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
  margin-bottom: 0px !important;
}

.copyLink {
  margin-top: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnCopyLink {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.4625rem 1rem !important;
}

.mediasContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  /* justify-content: space-between; */
}

.mediaEmptyFlex {
  flex: 1 0 21%;
}

.mediaContainer {
  flex: 1 0 21%;
  margin: 0.5625rem;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  min-width: 14rem;
  background-color: var(--background);
}

.mediaImage {
  width: 100%;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  position: relative;
}

.mediaImage::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.mediaContent {
  background-color: white;
  width: 100%;
  padding: 0.625rem 0.9375rem 0.8125rem 0.9375rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.mediaContainer:nth-child(4n + 1) {
  margin-left: 0;
}

.mediaContainer:nth-child(4n + 0) {
  margin-right: 0;
}

.mediaFolder {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.mediaFolder svg {
  margin-right: 0.625rem;
  color: var(--folder);
}

.mediaName {
  font-weight: 600;
  overflow: hidden;
  line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: flex;
  justify-content: space-between;
}

.mediaBottom {
  margin-top: 0.5625rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mediaBottomModal {
  margin-top: 0.5625rem;
  position: relative;
}

.mediaCategoryLabel {
  font-weight: 500;
  max-width: 40%;
  line-clamp: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mediaCategoryIcon {
  margin-right: 0.5rem;
}

.mediaCreator {
  color: var(--gray);
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mediaDescription1 {
  color: var(--gray-lighter);
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.mediaDescription2 {
  color: var(--gray-lighter);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.mediaInfo {
  font-weight: 500;
  color: var(--gray-lighter);
}

.mediaDots {
  cursor: pointer;
  padding: 0.5rem;
  margin-right: -0.25rem;
}

.foldersContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  /* justify-content: space-between; */
}

.folderPrivate {
  color: var(--folderPrivate) !important;
}

.folderPublic {
  color: var(--folder) !important;
}

/* .folder {
    flex: 1 0 15%;
    border: 1px solid var(--border-color);
    border-radius: .3125rem;
    padding: .625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.folder {
  border: 1px solid var(--border-color);
  border-radius: 0.3125rem;
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  min-width: 8rem;
  cursor: pointer;
}

.folder svg {
  width: 4rem;
  height: 4rem;
  color: var(--folder);
}

.folder p {
  margin: 0;
  margin-top: 0.5rem;
  text-align: center;
  word-break: break-word;
}

.popOver {
  top: 100%;
  margin-top: -1rem;
  left: 0px;
  filter: var(--drop-shadow-darker);
}

.modalDeleteButtons {
  display: flex;
}

.modalDeleteButtons > *:first-child {
  margin-right: 0.625rem;
}

.modalDeleteButtons > *:last-child {
  margin-left: 0.625rem;
}

.image {
  width: 100%;
  cursor: pointer;
}

.videoIconContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.videoIconContainer video {
  height: 100%;
}

.videoIcon {
  width: 2rem;
  height: 2rem;
}

.empty {
  background-color: var(--background);
  width: 100%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  cursor: pointer;
}

.emptyIcon {
  width: 5rem;
  height: 5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.topBar {
  display: flex;
  justify-content: space-between;
}

.select {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  cursor: pointer;
  position: relative;
  font-weight: 600;
  padding: 0 0.375rem;
  border-radius: 10px;
  padding: 0.5rem;
}

.select:hover {
  /* color: var(--primary-color-light); */
  box-shadow: 0 0 0 1px var(--primary-color-light);
}

.mediaSelected {
  filter: var(--hard-drop-shadow);
}

.isSelecting {
  background-color: var(--primary-color);
  color: var(--light-gray);
}

.isSelecting:hover {
  color: #fff;
}

.selectedCheck {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  right: 0.625rem;
  top: 0.625rem;
}

.mediaSelected .selectedCheck {
  display: flex;
}

.selectPopOver {
  position: absolute;
  top: 100%;
  width: 15rem;
  /* margin-top: -25rem; */
  right: 0px;
  filter: var(--drop-shadow-darker);
}

.isDownloading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7.1429rem;
  width: 7.1429rem;
  position: fixed;
  bottom: 0.1rem;
  right: 0.1rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 25px;
  z-index: 10;
}

.typeIcon {
  font-size: 1rem;
  color: var(--gray-lighter);
}

.rotateIcon {
  cursor: pointer;
}

.disabled {
  opacity: 0.6;
}

.previewImage {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  animation: spinner 4s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-styled-select__control {
  background-color: var(--light-gray) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.react-styled-select__control:hover {
  box-shadow: none !important;
}

.react-styled-select__indicator-separator {
  display: none;
}

.react-styled-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.react-styled-select__option--is-selected {
  background-color: rgba(54, 104, 175, 0.75) !important;
}

.react-styled-select__option--is-focused:not(.react-styled-select__option--is-selected) {
  background-color: var(--light-gray) !important;
}

.react-styled-select__option:not(:last-of-type) {
  border-bottom: 2px solid var(--light-gray);
}

.react-styled-select__option:first-of-type {
  border-radius: 4px 4px 0 0;
}

.react-styled-select__option:last-of-type {
  border-radius: 0 0 4px 4px;
}

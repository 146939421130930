.label {
  font-weight: 600;
  font-size: 0.875rem;
}

.range {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.filePreview {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 21.4286rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  position: relative;
}

.filePreview.empty {
  background-color: var(--background);
  color: var(--secondary-text);
}

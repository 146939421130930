.checkbox {
  display: flex;
  align-items: center;
  margin: 0;
  /* margin-top: 5px;
  margin-bottom: 5px; */
}

.checkbox:hover {
  cursor: pointer;
}

.checkbox > input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  margin-right: 5px;

  width: 1em;
  height: 1em;
  border: 1px solid var(--unselected-icon);
}

.checkbox > input:hover {
  cursor: pointer;
}

.checkbox > input:checked {
  background-color: var(--primary-color);
  border: none;
}

.checkbox > input:checked::before {
  content: url('../../svg/check.svg');
}

.invalid > input {
  border: 1px solid var(--red);
}

.invalid {
  color: var(--red);
}

.categoryModal {
  height: 36.4629rem;
}

.label {
  font-weight: 600;
  font-size: 0.875rem;
}

.range {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.previewImage {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 18.1071rem;
  background-position: center;
  border-radius: 5px;
}

.previewSize {
  top: -50px;
  font-size: 0.9rem;
  color: var(--gray);
  font-weight: 500;
}

.actionIcon {
  position: absolute;
  font-size: 1.25rem;
  color: var(--black);
  transition: color 0.2s;
}

.actionIconContainer {
  position: relative;
  user-select: none;
  height: 3.2143rem;
  width: 3.2143rem;
  margin-top: 1px;
  margin-bottom: 1px;
  margin-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionIconContainer:hover {
  cursor: pointer;
  color: var(--gray);
  box-shadow: 0 0 0 2px var(--button-hover);
  border-radius: 0.5rem;
}

.multiValueContainer {
  display: flex;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchSelect {
  flex-grow: 1;
  margin-right: 1rem;
}

.accessSelect {
  min-width: 9.2857rem;
  margin-right: 1rem;
}

.empty {
  display: flex;
  width: 100%;
  height: 5rem;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  color: var(--secondary-text);

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.userCategoriesTable {
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;
}

.userCategoriesTable th {
  text-align: left;
}

.userCategoriesTable tbody tr {
  border-top: 2px solid var(--border-color);
}

.titleCaret {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.privateDiv {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5em;
}

.privateSwitch {
  margin-left: 0.25rem;
}

.cloneTxt {
  color: var(--primary-color);
  cursor: pointer;
}

.cloneTxt:hover {
  text-decoration: underline;
}

.thePurgeButton {
  position: absolute;
  left: 1.5625rem ;
  top: .7143rem;  
}